<script setup lang="ts">
import type { ArticleContent } from '~/models/Content/ArticleContent'
import { computed } from 'vue'
import { KsSkeleton } from '@aschehoug/kloss'
import { firstOf } from '~/utils/queryUtils'
import { useMedia } from '~/composables/useMedia'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import Image from '~/components/media/Image.vue'

const props = defineProps<{
  articleContent: ArticleContent
}>()

const { data: media, isFetching } = firstOf(useMedia([Number(props.articleContent.image?.destinationContentId)]))
const reverse = computed(() => props.articleContent.reverse)
</script>

<template>
  <div
    class="grid grid-cols-subgrid bg-[--color-light] items-center"
    :class="media ? 'col-[--media-col-value] py-[--col-width]' : 'col-[--textsmallpadding-col-value] py-[--col-gap]'"
  >
    <KsSkeleton
      v-if="isFetching"
      class="col-span-5 row-start-1"
      :class="reverse ? '-col-end-3' : 'col-start-3'"
      height="300px"
    />
    <div
      v-else-if="media"
      class="relative col-span-5 row-start-1"
      :class="reverse ? '-col-end-3' : 'col-start-3'"
    >
      <Image
        :content="media"
        class="!w-full max-h-[90lvh] object-contain"
        width="400px"
      />
      <div
        v-if="'copyright' in media && media.copyright"
        class="flex flex-col bg-white p-4"
      >
        <RichTextRenderer :text="media.caption" />
        <small v-if="media.copyright">{{ $t('copyright.image', { copyright: media.copyright }) }}</small>
      </div>
    </div>

    <div
      class="au-prose"
      :class="{
        'col-span-5 col-start-3': (media || isFetching) && reverse,
        'col-span-5 -col-end-3': (media || isFetching) && !reverse,
        'col-start-2 -col-end-2': !media,
      }"
    >
      <RichTextRenderer :text="articleContent.body" />
    </div>
  </div>
</template>
