<script lang="ts" setup>
import type { ContentVideo } from '~/models/Content/ContentVideo'
import { ref, watch } from 'vue'
import { KsSkeleton } from '@aschehoug/kloss'
import { setTitle } from '~/utils/dom'
import useLicenseControlStore from '~/stores/licenseControl'
import { Subtree } from '~/models/Content/Subtree'
import { ContentType } from '~/models/Content/ContentType'
import useContentApi from '~/api/contentApi'
import ThirdPartyEmbed from '~/components/utils/ThirdPartyEmbed.vue'
import CloseButton from '~/components/utils/CloseButton.vue'
import NotFound from '~/components/ResourceEngine/Components/NotFound.vue'
import VideoPlayer from '~/components/media/VideoPlayer.vue'

const { checkItemLicense } = useLicenseControlStore()
const { findContents } = useContentApi()

const props = defineProps<{ locationId: number }>()

const content = ref<ContentVideo>()
const isLoading = ref(true)
const hasError = ref(false)

watch(content, () => content.value && checkItemLicense(content.value))

async function fetchContent(locationId: number) {
  try {
    isLoading.value = true
    content.value = (await findContents<ContentVideo>({
      subtreeCriterion: [Subtree.Media, Subtree.Content],
      locationIdCriterion: [locationId],
      contentTypeCriterion: [ContentType.Video],
    }, 1))[0]
    if (!content.value) throw new Error(`Video ${locationId} not found`)
  } catch (error) {
    hasError.value = true
    throw error
  } finally {
    isLoading.value = false
  }
}

watch(() => props.locationId, (locationId) => fetchContent(locationId), { immediate: true })

watch(() => content.value,() => {
  if (!content.value) return
  setTitle(content.value.title)
})
</script>

<template>
  <NotFound v-if="hasError" />
  <template v-else>
    <div
      class="grid h-full grid-cols-[minmax(0,120ch)] grid-rows-[max-content] place-content-center gap-y-6 px-2 py-6 font-inter"
      :class="{ 'bg-black text-white': isLoading || content }"
    >
      <template v-if="isLoading">
        <KsSkeleton
          width="33%"
          height="4rem"
        />

        <KsSkeleton
          width="100%"
          height="auto"
          class="aspect-video"
        />
      </template>

      <template v-else-if="content">
        <CloseButton :item="content" />
        <h1
          class="text-5xl font-bold"
          v-text="content.title"
        />

        <ThirdPartyEmbed
          v-if="content.embedCode"
          :code="content.embedCode"
        />

        <VideoPlayer
          v-else-if="content.metadata"
          :media-id="content.metadata.contentItemId"
          :src="content.metadata.elementURI"
          :poster-src="content.metadata.image"
        />
      </template>
    </div>
  </template>
</template>
