<script setup lang="ts">
import type { GradeCode } from '~/models/Grade'
import type { ContentProductHeader } from '~/models/Content/ContentProductHeader'
import type { BaseItem } from '~/models/Content/BaseItem'
import { computed } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { KsSkeleton, KsSkeletonWrapper } from '@aschehoug/kloss'
import { colors, forceColorShade, getColorForId } from '~/utils/colors'
import { ContentType } from '~/models/Content/ContentType'
import useContentApi from '~/api/contentApi'
import StackedCard from '~/components/cards/StackedCard.vue'
import SearchCard from '~/components/cards/SearchCard.vue'

const { headers, selectedGrades, isLoading } = defineProps<{
  headers: ContentProductHeader[]
  selectedGrades: GradeCode[]
  isLoading: boolean
}>()

const { findContents } = useContentApi()

const { data: groupedResources, isLoading: isResourcesLoading } = useQuery({
  queryKey: computed(() => ['product_exams_resources', headers, selectedGrades]),
  queryFn: async () => await findContents<BaseItem>({
    parentLocationIdCriterion: headers.map(r => r.locationId),
    contentTypeCriterion: [ContentType.ProductPackage, ContentType.LearningPath],
    gradeFieldCriterion: selectedGrades,
    gradeOperator: 'or',
    sortField: 'priority',
    sortOrder: 'asc',
  }),
  select: resources => groupResourcesByLocationId(headers, resources),
  enabled: computed(() => !!headers.length),
  staleTime: Infinity,
})

function groupResourcesByLocationId(
  headers: ContentProductHeader[],
  resources: BaseItem[]
) {
  return headers.map(header => ({
    header,
    resources: resources.filter(resource => resource.parentLocationId === header.locationId)
  }))
}
</script>

<template>
  <div>
    <div
      v-if="!isLoading && !isResourcesLoading"
      class="space-y-8"
    >
      <section
        v-for="(group, locationId) in groupedResources"
        :key="locationId"
        class="space-y-2"
      >
        <h2
          v-if="group.resources.length"
          class="text-2xl font-bold"
          v-text="group.header.title"
        />
        <ul class="grid grid-cols-1 gap-4 xs:grid-cols-2 lg:grid-cols-3">
          <StackedCard
            v-for="resource in group.resources"
            :key="resource.locationId"
            :class="resource.thumbnail ? 'row-span-2' : 'row-span-1'"
            :item="resource"
            class="size-full"
            :depth="2"
            element="div"
            :stack-offset="3"
            padded-stack
            :colors="[forceColorShade(getColorForId(resource.parentLocationId, colors), '20')]"
          >
            <template #default="{ item }">
              <SearchCard
                v-if="item"
                :item="item"
                package-style
              />
            </template>
          </StackedCard>
        </ul>
      </section>
    </div>
    <div
      v-for="wrapper in 3"
      v-else
      :key="wrapper"
    >
      <KsSkeletonWrapper class="mb-8 space-y-2">
        <KsSkeleton
          height="36px"
          width="120px"
        />
        <ul class="grid grid-cols-1 gap-4 xs:grid-cols-2 lg:grid-cols-3">
          <KsSkeleton
            v-for="item in 3"
            :key="item"
            height="200px"
          />
        </ul>
      </KsSkeletonWrapper>
    </div>
  </div>
</template>
