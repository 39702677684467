<script setup lang="ts">
import type { ArticleContent } from '~/models/Content/ArticleContent'
import { computed } from 'vue'
import { KsSkeleton } from '@aschehoug/kloss'
import { firstOf } from '~/utils/queryUtils'
import { useMedia } from '~/composables/useMedia'
import RichTextRenderer from '~/components/utils/RichTextRenderer.vue'
import CopyrightButton from '~/components/utils/CopyrightButton.vue'
import Image from '~/components/media/Image.vue'

const props = defineProps<{
  articleContent: ArticleContent
}>()

const { data: media, isFetching } = firstOf(useMedia([Number(props.articleContent.image?.destinationContentId)]))
const reverse = computed(() => props.articleContent.reverse)
</script>

<template>
  <section class="col-[--media-col-value] grid grid-cols-subgrid items-center">
    <KsSkeleton
      v-if="isFetching"
      class="col-span-5 row-start-1 aspect-square !size-full !rounded-full"
      :class="reverse ? '-col-end-1' : 'col-start-1'"
    />
    <div
      v-else-if="media"
      class="relative col-span-5 row-start-1"
      :class="reverse ? '-col-end-1' : 'col-start-1'"
    >
      <Image
        v-if="media"
        :content="media"
        class="aspect-square !h-full rounded-full object-cover"
        width="400px"
      />
      <CopyrightButton
        v-if="'copyright' in media && media.copyright"
        :copyright="media.copyright"
        class="!absolute bottom-0 right-0"
      />
    </div>
    <div
      class="au-prose au-subtitle font-semibold"
      :class="{
        'col-start-1 -col-end-7': (media || isFetching) && reverse,
        'col-start-7 -col-end-1': (media || isFetching) && !reverse,
        'col-start-1 -col-end-1': !media,
      }"
    >
      <RichTextRenderer :text="articleContent.body" />
    </div>
  </section>
</template>
