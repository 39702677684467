<script setup lang="ts">
import type { GradeCode } from '~/models/Grade'
import type { ContentProductHeader } from '~/models/Content/ContentProductHeader'
import type { ContentProduct } from '~/models/Content/ContentProduct'
import { computed, ref, watch } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { lowerSecondaryGrades } from '~/utils/gradeSorter'
import { setTitle } from '~/utils/dom'
import { ContentType } from '~/models/Content/ContentType'
import { ColorName } from '~/models/Content/Color'
import useSearchColors from '~/composables/useSearchColors'
import { useAppColor } from '~/composables/useAppColor'
import useContentApi from '~/api/contentApi'
import ExamsSearchResults from '~/components/products/exams/ExamsSearchResults.vue'
import ExamsSearchFilters from '~/components/products/exams/ExamsSearchFilters.vue'
import EditAssignTaskDialog from '~/components/products/exams/assignment/EditAssignTaskDialog.vue'
import AssignTaskDialog from '~/components/products/exams/assignment/AssignTaskDialog.vue'

const { product } = defineProps<{ product: ContentProduct }>()

useAppColor(ColorName.Blue5)

const { colorVariables } = useSearchColors()
const { findContents } = useContentApi()

const selectedGrades = ref<GradeCode[]>(lowerSecondaryGrades)

const {
  data,
  isError,
  isLoading,
} = useQuery({
  queryKey: computed(() => ['product_exams', product.locationId]),
  queryFn: async () => await findContents<ContentProductHeader>({
    parentLocationIdCriterion: [product.locationId],
    contentTypeCriterion: [ContentType.ProductHeader],
    sortField: 'priority',
    sortOrder: 'asc',
  }),
  enabled: computed(() => !!product.pathString),
  staleTime: Infinity,
})

const headers = computed(() => data.value || [])

watch(() => product, () => setTitle(product.title), { immediate: true })
</script>
<template>
  <div
    :style="colorVariables"
    class="mx-auto mb-12 mt-28 grid max-w-screen-au grid-cols-1 grid-rows-[auto,auto,auto,1fr,auto] gap-x-8 gap-y-4 px-4 text-blue-60 grid-areas-exams-view-mobile sm:px-8 md:grid-cols-3 md:gap-x-12 md:grid-areas-exams-view-tablet"
  >
    <h1
      class="text-3xl font-extrabold grid-in-heading"
      v-text="product.title"
    />
    <ExamsSearchFilters v-model="selectedGrades" />
    <ExamsSearchResults
      :headers
      :selected-grades
      :is-loading
      class="!grid-cols-2 grid-in-results"
    />
    <p v-if="isError">
      Noe gikk galt.
    </p>
  </div>
  <AssignTaskDialog v-if="false" />
  <EditAssignTaskDialog
    v-if="false"
    task-id="1"
  />
</template>
