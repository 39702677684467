<script setup lang="ts">
import type { ContentFlytTask } from '~/models/Content/ContentFlytTask'
import type { ContentFlashcardDeck } from '~/models/Content/ContentFlashcards'
import type { ArticleContent } from '~/models/Content/ArticleContent'
import { computed, provide, watch } from 'vue'
import { useQuery } from '@tanstack/vue-query'
import { KsSkeletonWrapper } from '@aschehoug/kloss'
import { setTitle } from '~/utils/dom'
import { colorMap } from '~/utils/colors'
import useLicenseControlStore from '~/stores/licenseControl'
import { ContentType } from '~/models/Content/ContentType'
import { type ContentImageCollection, ImageCollectionMode } from '~/models/Content/ContentImageCollection'
import { ArticleSize, type ContentArticle } from '~/models/Content/ContentArticle'
import { ColorName } from '~/models/Content/Color'
import { useContentHelper } from '~/composables/useContentHelper'
import { useAppColor } from '~/composables/useAppColor'
import useContentApi from '~/api/contentApi'
import TopBar from '~/components/utils/TopBar.vue'
import NotFound from '~/components/ResourceEngine/Components/NotFound.vue'
import FlytTask from '~/components/flyt/FlytTask.vue'
import TextCarousel from '~/components/article/TextCarousel.vue'
import ModelText from '~/components/article/ModelText.vue'
import ImageCollection from '~/components/article/ImageCollection.vue'
import ArticleHeaderRenderer from '~/components/article/ArticleHeaderRenderer.vue'
import ArticleContentRenderer from '~/components/article/ArticleContentRenderer.vue'
import AppLogo from '~/components/AppLogo.vue'

const props = defineProps<{
  locationId: number
}>()

provide('embedAudioVariant', 'inline')
const appColor = useAppColor(ColorName.White)

const { findContents } = useContentApi()
const { isArticleContent, isImageCollection, isModelText, isFlytTask, isFlashcards } = useContentHelper()
const { checkItemLicense } = useLicenseControlStore()

const { data, isLoading } = useQuery({
  staleTime: Infinity,
  queryKey: ['app-article', props.locationId],
  queryFn: () => findContents<ContentArticle>({
      locationIdCriterion: [props.locationId],
      contentTypeCriterion: [ContentType.Article],
    }, 1)
    .then(([data]) => data)
})

watch(data, () => {
  if (!data.value) return
  setTitle(data.value.title)
  checkItemLicense(data.value)
}, { immediate: true })

const { data: sections } = useQuery({
  staleTime: Infinity,
  queryKey: ['app-article-content', props.locationId],
  async queryFn() {
    if (!data.value || !data.value.content) return null
    const contentIds = data.value.content.destinationContentIds
    const articleContent = await findContents<ArticleContent | ContentImageCollection | ContentFlytTask | ContentFlashcardDeck>({
      contentIdCriterion: contentIds,
      contentTypeCriterion: [
        ContentType.ArticleContent,
        ContentType.ImageCollection,
        ContentType.FlytTask,
        ContentType.Flashcards,
        ContentType.ModelText,
      ],
      mainLocationCriterion: true,
    })
    return articleContent.sort((a, b) => contentIds.indexOf(a.contentId) - contentIds.indexOf(b.contentId))
  },
  enabled: computed(() => (data.value?.content?.destinationContentIds.length ?? 0) > 0)
})

const size = computed(() => data.value?.size.identifier ?? ArticleSize.Medium)

const colors = computed(() => {
  const [defaultColor] = colorMap.values()

  switch (data.value?.colorTheme.identifier) {
    case 'violet':
      return {
        darker: colorMap.get(ColorName.Violet60) ?? defaultColor,
        dark: colorMap.get(ColorName.Violet50) ?? defaultColor,
        pop: colorMap.get(ColorName.YellowPop) ?? defaultColor,
        light: colorMap.get(ColorName.Violet10) ?? defaultColor,
        lighter: colorMap.get(ColorName.Yellow5) ?? defaultColor,
      }

    case 'blue':
      return {
        darker: colorMap.get(ColorName.Blue60) ?? defaultColor,
        dark: colorMap.get(ColorName.Blue50) ?? defaultColor,
        pop: colorMap.get(ColorName.OrangePop) ?? defaultColor,
        light: colorMap.get(ColorName.Orange10) ?? defaultColor,
        lighter: colorMap.get(ColorName.Orange5) ?? defaultColor,
      }

    case 'green':
      return {
        darker: colorMap.get(ColorName.Green60) ?? defaultColor,
        dark: colorMap.get(ColorName.Green50) ?? defaultColor,
        pop: colorMap.get(ColorName.SeagreenPop) ?? defaultColor,
        light: colorMap.get(ColorName.Green10) ?? defaultColor,
        lighter: colorMap.get(ColorName.Green5) ?? defaultColor,
      }

    case 'coral':
    default:
      return {
        darker: colorMap.get(ColorName.Coral60) ?? defaultColor,
        dark: colorMap.get(ColorName.Coral50) ?? defaultColor,
        pop: colorMap.get(ColorName.CoralPop) ?? defaultColor,
        light: colorMap.get(ColorName.Coral10) ?? defaultColor,
        lighter: colorMap.get(ColorName.Coral5) ?? defaultColor,
      }
  }
})

const fontSize = computed(() => {
  switch (size.value) {
    case 'medium': return 1.2
    case 'large': return 1.375
    case 'xlarge': return 2
  }
})

const textColStart = computed(() => {
  switch (size.value) {
    case 'medium': return 8
    case 'large': return 6
    case 'xlarge': return 4
  }
})

const mediaColStart = computed(() => {
  switch (size.value) {
    case 'medium': return 4
    case 'large': return 4
    case 'xlarge': return 2
  }
})

watch(colors, (theme) => {
  appColor.set(theme.lighter.name)
}, { immediate: true })
</script>

<template>
  <TopBar
    variant="colorPair"
    :item="data"
    :color-pair="{
      background: colors.lighter,
      text: colors.darker,
      isDark: false,
    }"
  />

  <KsSkeletonWrapper v-if="isLoading" />

  <NotFound v-else-if="!data" />

  <article
    v-else
    :style="{
      '--col-width': '3.75rem',
      '--col-gap': '2rem',
      '--text-width': '36ch',
      '--text-col-value': `${textColStart} / ${textColStart * -1}`,
      '--textpadding-col-value': `${textColStart - 2} / ${(textColStart - 2) * -1}`,
      '--textsmallpadding-col-value': `${textColStart - 1} / ${(textColStart - 1) * -1}`,
      '--media-col-value': `${mediaColStart} / ${mediaColStart * -1}`,
      '--color-lighter': colors.lighter.rgb,
      '--color-light': colors.light.rgb,
      '--color-pop': colors.pop.rgb,
      '--color-dark': colors.dark.rgb,
      '--color-darker': colors.darker.rgb,
      '--media-color': colors.pop.rgb,
    }"
    class="au-article grid min-h-full auto-rows-max grid-cols-[minmax(1rem,1fr),repeat(4,var(--col-width)_var(--col-gap)),minmax(0,calc(var(--text-width)/2)),repeat(2,calc(var(--col-gap)/2)),minmax(0,calc(var(--text-width)/2)),repeat(4,var(--col-gap)_var(--col-width)),minmax(1rem,1fr)] gap-y-[--col-width] bg-[--color-lighter] text-[--color-darker]"
  >
    <ArticleHeaderRenderer :article="data" />
    <!-- Words -->
    <section
      v-if="data.wordlist.length"
      class="flex items-center gap-4 font-semibold text-[--color-darker]"
      :class="true ? 'col-start-2 -col-end-2' : 'col-start-4 -col-end-4'"
    >
      <h2
        class="grid size-[calc(var(--col-width)+var(--col-gap))] place-items-center rounded-full bg-[--color-pop] uppercase"
        :class="{ 'au-subtitle': size !== ArticleSize.XLarge }"
      >
        {{ $t('article.word') }}:
      </h2>
      <ul class="contents">
        <li
          v-for="word in data.wordlist"
          :key="word"
          class="rounded-md bg-[--color-pop] px-3 py-1"
        >
          {{ word }}
        </li>
      </ul>
    </section>

    <!-- Content -->
    <template
      v-for="item in sections"
      :key="item.locationId"
    >
      <ModelText
        v-if="isModelText(item)"
        class="col-[--media-col-value]"
        :content="item"
        :size
      />

      <ArticleContentRenderer
        v-else-if="isArticleContent(item)"
        :article-content="item"
      />
      <section
        v-else-if="isImageCollection(item)"
        :class="{
          '!col-[1/-1]': item.mode === ImageCollectionMode.Carousel,
          'col-[3/-3]': Number(item.images?.destinationContentIds?.length) > 2,
          'col-[5/-5]': Number(item.images?.destinationContentIds?.length) <= 2,
        }"
      >
        <ImageCollection :content="item" />
      </section>

      <section
        v-else-if="isFlytTask(item)"
        class="col-[--media-col-value] mx-1 mb-12 mt-20 rounded-xl bg-[#ffead1ff] p-6 font-inter max-md:px-4 sm:mx-4 lg:mx-auto lg:max-w-[var(--flyt-ideal-content-width-wide)]"
      >
        <FlytTask
          :task-id="item.taskId"
          :mode="item.mode"
        />
      </section>

      <TextCarousel
        v-else-if="isFlashcards(item)"
        class="col-[1/-1] grid bg-[--color-dark] px-[--col-width] pt-[--col-width] text-[--color-pop]"
        :content="item"
      />
    </template>

    <footer class="col-start-1 -col-end-1 grid place-content-center p-12">
      <AppLogo />
    </footer>
  </article>
</template>

<style scoped>
.au-article {
  font-size: calc(1rem * v-bind(fontSize));

  & :deep(.au-subtitle), & :deep(.cms-h) {
    font-size: 1.4em;
  }

  & :deep(.au-title) {
    font-size: 2.8em;
  }

  :deep(.au-prose > :not(:first-child)) {
    margin-block-start: 1em;
  }

  :deep(.au-prose .cms-ul) {
    list-style-type: disc;
    margin-inline-start: 1em;
  }

  :deep(.au-prose .cms-ol) {
    list-style-type: decimal;
    margin-inline-start: 1em;
  }

  :deep(.au-prose .cms-li:not(:first-child)) {
    margin-block-start: .25em;
  }

  :deep(.cms-blockquote), :deep(.quoteauthor-textfield blockquote) {
    font-family: tiempos, serif;
    font-size: 2.8em;
    position: relative;
    
    .cms-p {
      display: inline;
      padding-inline: 1rem;
      box-decoration-break: clone;
      background-color: var(--color-light);
    }

    &::before {
      display: block;
      content: '';
      mask-image: url('/quote.svg');
      mask-size: 100%;
      mask-repeat: no-repeat;
      width: 4rem;
      height: 4rem;
      background-color: var(--color-dark);
      
      @media (min-width: 768px) {
        position: absolute;
        right: calc(100% + 1rem);
        top: -1rem;
      }
    }
  }
  
  :deep(.quoteauthor-textfield blockquote ~ *) {
    display: inline-block;
    margin-top: 1rem;
    padding-inline: 1rem;
    background-color: var(--color-light);
  }
}
</style>
